<template>
  <div id="Header" :class="{
    scr:
      scroll > 0 &&
      $route.name !== 'home' &&
      $route.name != 'lastest-proyects' &&
      $route.name !== 'about' &&
      $route.name !== 'team' &&
      $route.name != 'stories' &&
      $route.name != 'services' &&
      $route.name != 'partners' &&
      $route.name != 'magazine' &&
      $route.name != 'contact-us',

    'white-fil': $route.name.includes('white') && !fill
  }">
    <div class="header-cont">
      <div class="logo-cont">
        <a href="/">
          <img :class="{
    bright:
      this.$router.currentRoute.path == '/agencia-de-marketing ' ||
      this.$router.currentRoute.path == '/services/development' ||
      this.$router.currentRoute.path == '/branding',
  }" :src="legraficaLogo" alt="legrafica" class="logo logoLegra" />
        </a>
        <div class="anchor">
          <div class="anchor-text">
            <div class="anch">
              <p class="texto-inf" :class="{
    bright: this.$router.currentRoute.path == '/branding',
  }">
                <span class="text-anchor-r">
                  <span>The web, dev & mkt agency. </span>
                  <span>The web, dev & mkt agency. </span>
                  <span>The web, dev & mkt agency. </span>
                  <span>The web, dev & mkt agency. </span>
                  <span>The web, dev & mkt agency. </span>
                  <span>The web, dev & mkt agency. </span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="contact-menu">
        <a href="/home/contact-us" class="contactus" :class="{
    bright:
      this.$router.currentRoute.path == '/agencia-de-marketing ' ||
      this.$router.currentRoute.path == '/services/development' ||
      this.$router.currentRoute.path == '/branding',
  }">
          Contact us
        </a>
        <div class="menu-btn-cont">
          <input type="checkbox" name="check" id="checkmenu" v-model="istrue1" :true-value="true"
            :false-value="false" />
          <label for="checkmenu" @click="animarFill">
            <div class="menu-btn" :class="{
    nobr: istrue1,
    bright:
      this.$router.currentRoute.path == '/agencia-de-marketing ' ||
      this.$router.currentRoute.path == '/services/development' ||
      this.$router.currentRoute.path == '/branding',
  }">
              <div class="bars-cont" :class="{ animation1: istrue1, animation2: istrue2 }">
                <div class="bar uno" />
                <div class="bar due" />
              </div>
            </div>
          </label>

          <!--header menu-->
          <div class="menu-desp">
            <div class="menu-cont">
              <div class="img-cont">
                <div :style="{ 'background-image': 'url(' + imagehover + ')' }" alt="img" class="img-1" width="572"
                  height="780" />
              </div>
              <div class="menu-items">
                <a class="btnhomemenu" href="/">
                  <img src="../../assets/Group.svg" alt="" />
                </a>
                <p class="title">Hello.</p>
                <div @mouseleave="hoverImage('null')">
                  <a @click="closecheck" @mouseover="hoverImage('projects')" :href="'/portafolio'"
                    class="menu-i"><span>01</span>
                    <p>Projects</p>
                  </a>
                  <a @click="closecheck" @mouseover="hoverImage('about')" :href="'/about'"
                    class="menu-i"><span>02</span>
                    <p>About</p>
                  </a>
                  <a @click="closecheck" @mouseover="hoverImage('services')" :href="'/services'"
                    class="menu-i"><span>03</span>
                    <p>Services</p>
                  </a>
                  <a @click="closecheck" @mouseover="hoverImage('partners')" href="/home/partners" class="menu-i pc">
                    <div style="display: flex; cursor: pointer">
                      <span>04</span>
                      <p>Partners</p>
                    </div>
                  </a>
                  <a @click="closecheck" @mouseover="hoverImage('partners')" href="/home/partners" class="menu-i mo">
                    <div style="display: flex; cursor: pointer">
                      <span>04</span>
                      <p>Partners</p>
                    </div>
                  </a>
                  <a href="/historias-de-exito" class="menu-i" @mouseover="hoverImage('hde')"><span>05</span>
                    <p>Historias de éxito</p>
                  </a>
                  <a @click="closecheck" @mouseover="hoverImage('magazine')" :href="'/magazine'"
                    class="menu-i mo"><span>06</span>
                    <p>Magazine</p>
                  </a>
                </div>
              </div>
              <div class="sus-con-cont">
                <div class="susc-cont movhid">
                  <p class="s-titulo">Suscríbete</p>
                  <p class="s-descr">
                    Recibe las noticias más importantes de marketing en tu
                    email.
                  </p>
                  <div class="txt-cont">
                    <form @submit.prevent="submit(email_form)">
                      <label></label>
                      <input type="text" v-model="email_form" name="nombre" placeholder="Tu correo electrónico." />
                      <input class="btnsbt" type="submit" value="" />
                    </form>
                  </div>
                  <div v-if="this.status == 'success'">
                    <p class="cart_msg cart_success">{{ this.msg }}</p>
                  </div>
                  <div v-if="this.status == 'error'">
                    <p class="cart_msg cart_error">{{ this.msg }}</p>
                  </div>
                </div>
                <div class="con-cont">
                  <div class="t-cont" @mouseleave="hoverImage('null')">
                    <a @click="closecheck" @mouseover="hoverImage('magazine')" :href="'/magazine'"
                      class="menu-i pc"><span>06</span>
                      <p>Magazine</p>
                    </a>

                    <a @click="closecheck" href="/home/contact-us" class="menu-i pc"
                      @mouseover="hoverImage('contactus')">
                      <div style="display: flex; cursor: pointer">
                        <span>07</span>
                        <p>Contactanos</p>
                      </div>
                    </a>

                    <a @click="closecheck" href="" class="menu-i mo" @mouseover="hoverImage('contactus')">
                      <div style="display: flex; cursor: pointer">
                        <span>07</span>
                        <p>Contactanos</p>
                      </div>
                    </a>
                  </div>
                  <div class="sm-cr-cont">
                    <div class="sm-cont">
                      <a href="https://es-la.facebook.com/legrafica/" target="_blank" rel="noopener noreferrer">
                        <div :style="{
    'background-image':
      'url(' + require('../../assets/fb-i.svg') + ')',
  }" alt="sm" class="sm-logo fb-icon" width="20" height="20" />
                      </a>
                      <a href="https://www.instagram.com/legrafica/?hl=es" target="_blank" rel="noopener noreferrer">
                        <div :style="{
    'background-image':
      'url(' + require('../../assets/ig-i.svg') + ')',
  }" alt="sm" class="sm-logo" width="20" height="20" />
                      </a>
                      <a href="https://twitter.com/legrafica" target="_blank" rel="noopener noreferrer">
                        <div :style="{
    'background-image':
      'url(' + require('../../assets/tw-i.svg') + ')',
  }" alt="sm" class="sm-logo" width="20" height="20" />
                      </a>
                      <a href="https://mx.linkedin.com/company/legrafica" target="_blank" rel="noopener noreferrer">
                        <div :style="{
    'background-image':
      'url(' + require('../../assets/li-i.svg') + ')',
  }" alt="sm" class="sm-logo" width="20" height="20" />
                      </a>
                    </div>
                    <p class="copyright">
                      Copyright 2022 © Todos los Derechos Reservados.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--fin menu-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      istrue1: false,
      istrue2: false,
      fill: false,
      legraficaLogo: require("../../assets/logo_3.svg"),
      imagehover: require("../../assets/menu-8.webp"),
      email_form: "",
      msg: "",
      status: "",
      scroll: 0,
      scrollAnterior: null,
      movil: false,
    };
  },
  props: {
    white: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    scrollroll(event) {
      let number = event.target.documentElement.scrollTop;
      localStorage.setItem("scr", number);
      this.scroll = localStorage.getItem("scr");
    },

    animarFill() {
      !this.fill ? this.fill = !this.fill : setTimeout(() => (this.fill = !this.fill), 1000);
    },
    /**/
    /*gotosec(){
       if(this.$route.params.name == 'partners'){
          window.scrollTo(0,31000)
      }
    },
    gotosec12(){
       if(this.$route.params.name == 'partners'){
          window.scrollTo(0,17354)
      }
    },
    gotosec2(){
       if(this.$route.params.name == 'contact-us'){
          window.scrollTo(0,50000)
      }
    },
    gotosec22(){
       if(this.$route.params.name == 'contact-us'){
          window.scrollTo(0,24954)
      }
    },*/
    /*** /marketing */
    changeColor(event) {
      let media768 = window.matchMedia("(max-width: 768px)");
      let min768 = window.matchMedia("(min-width: 768px)");
      /***57.59615384615384
       * contactus
       * whiteword
       * menu-btn
       *   background-image: url("../assets/fondiu-1.svg"); morepro morep*/
      let textAnchor = document.getElementsByClassName("text-anchor-r")[0];
      let whitew = document.getElementsByClassName("contactus")[0];
      let menub = document.getElementsByClassName("menu-btn")[0];
      let legraficaLogo = document.getElementsByClassName("logoLegra")[0];
      let number = event.target.documentElement.scrollTop;
      let offset = number * 0.09615384615384615;
      if (media768.matches) {
        if (offset > 2490) {
          //  morep.style.color = 'white';
          textAnchor.style.color = "#ef67c2!important";
        } else if (offset < 2490) {
          //  morep.style.color = '#b2acfc';
          textAnchor.style.color = "white!important";
        }
        if (offset < 57) {
          if (this.$router.currentRoute.path == "/agencia-de-marketing ") {
            legraficaLogo.style.filter = "brightness(2)";
            whitew.style.filter = "brightness(5.5)";
            menub.style.filter = "brightness(5.5)";
          }
          if (this.$router.currentRoute.path == "/services/development") {
            legraficaLogo.style.filter = "brightness(2)";
            whitew.style.filter = "brightness(5.5)";
            menub.style.filter = "brightness(5.5)";
          }
          if (this.$router.currentRoute.path == "/branding") {
            legraficaLogo.style.filter = "brightness(2)";
            whitew.style.filter = "brightness(5.5)";
            menub.style.filter = "brightness(5.5)";
          }
        }
        if (offset > 57) {
          if (this.$router.currentRoute.path == "/agencia-de-marketing ") {
            legraficaLogo.style.filter = "brightness(1)";
            whitew.style.filter = "brightness(1)";
            menub.style.filter = "brightness(1)";
          }
          if (this.$router.currentRoute.path == "/services/development") {
            legraficaLogo.style.filter = "brightness(1)";
            whitew.style.filter = "brightness(1)";
            menub.style.filter = "brightness(1)";
          }
          if (this.$router.currentRoute.path == "/branding") {
            legraficaLogo.style.filter = "brightness(1)";
            whitew.style.filter = "brightness(1)";
            menub.style.filter = "brightness(1)";
          }
        }
      } else if (min768.matches) {
        if (offset < 57) {
          if (this.$router.currentRoute.path == "/agencia-de-marketing ") {
            legraficaLogo.style.filter = "brightness(2)";
            whitew.style.filter = "brightness(5.5)";
            menub.style.filter = "brightness(5.5)";
          }
          if (this.$router.currentRoute.path == "/services/development") {
            legraficaLogo.style.filter = "brightness(2)";
            whitew.style.filter = "brightness(5.5)";
            menub.style.filter = "brightness(5.5)";
          }
          if (this.$router.currentRoute.path == "/branding") {
            legraficaLogo.style.filter = "brightness(2)";
            whitew.style.filter = "brightness(5.5)";
            menub.style.filter = "brightness(5.5)";
          }
        }
        if (offset > 57) {
          if (this.$router.currentRoute.path == "/agencia-de-marketing ") {
            legraficaLogo.style.filter = "brightness(1)";
            whitew.style.filter = "brightness(1)";
            menub.style.filter = "brightness(1)";
          }
          if (this.$router.currentRoute.path == "/services/development") {
            legraficaLogo.style.filter = "brightness(1)";
            whitew.style.filter = "brightness(1)";
            menub.style.filter = "brightness(1)";
          }
          if (this.$router.currentRoute.path == "/branding") {
            legraficaLogo.style.filter = "brightness(1)";
            whitew.style.filter = "brightness(1)";
            menub.style.filter = "brightness(1)";
          }
        }
        if (offset > 2271) {
          //  morep.style.color = 'white'; 3326 logoLegra
          textAnchor.style.color = "white";
          whitew.style.color = "white";
          //this.legraficaLogo =  require("../../assets/legra-white.svg");
          legraficaLogo.style.filter = "brightness(5.5)";
          menub.style.filter = "brightness(5.5)";
        }
        if (offset < 2270) {
          if (this.$router.currentRoute.path == "/") {
            legraficaLogo.style.filter = "brightness(1)";
            menub.style.filter = "brightness(1)";
            whitew.style.color = "#987ef3";
          }
          textAnchor.style.color = "#ef67c2";
          //this.legraficaLogo =  require("../../assets/logo_3.svg");
          //  morep.style.color = '#b2acfc';menu-btn
        }
        if (offset > 3153) {
          //  morep.style.color = 'white'; 3326
          whitew.style.color = "#987ef3";
          menub.style.filter = "brightness(1)";
        }
        if (offset > 3326) {
          //  morep.style.color = 'white'; 3326 3575
          textAnchor.style.color = "#ef67c2";
        }
        if (offset > 3575) {
          //  morep.style.color = 'white'; 3326 3575
          //this.legraficaLogo =  require("../../assets/logo_3.svg");
          legraficaLogo.style.filter = "brightness(1)";
        }
      }
    },
    hoverImage(text) {
      if (text == "projects") {
        this.imagehover = require("../../assets/menu-2.png");
      }
      if (text == "about") {
        this.imagehover = require("../../assets/menu-3.png");
      }
      if (text == "services") {
        this.imagehover = require("../../assets/menu-7.png");
      }
      if (text == "partners") {
        this.imagehover = require("../../assets/menu-6.png");
      }
      if (text == "magazine") {
        this.imagehover = require("../../assets/menu-1.png");
      }
      if (text == "hde") {
        this.imagehover = require("../../assets/menu-5.png");
      }
      if (text == "contactus") {
        this.imagehover = require("../../assets/menu-4.png");
      }
      if (text == "null") {
        this.imagehover = require("../../assets/menu-8.webp");
      }
    },
    routeRef(linkuno, linkdos) {
      if (this.$route.params.id == "/") {
        return linkuno;
      } else {
        return linkdos;
      }
    },
    istrue() {
      let check = document.getElementById("checkmenu");
      if (check.checked == true) {
        this.istrue1 = true;
      }
    },
    isfalse() {
      let check = document.getElementById("checkmenu");
      if (check.checked !== true) {
        this.istrue2 = true;
      }
    },
    closecheck() {
      document.getElementById("checkmenu").checked = false;
    },
    contactbtn() {
      let min768 = window.matchMedia("(min-width: 768px)");
      let media768 = window.matchMedia("(max-width: 768px)");
      if (media768.matches) {
        if (this.$route.name == 'partners') {
          window.scrollTo(0, 17441)
        }
        if (this.$route.name == "contact-us") {
          window.scrollTo(0, 24975);
        }
      } else if (min768.matches) {
        if (this.$route.name == 'partners') {
          window.scrollTo(0, 30836)
        }
        if (this.$route.name == "contact-us") {
          window.scrollTo(0, 40236);
        }
      }
    },
    submit: async function (email) {
      let result = await this.$store.dispatch("main/addSuscriptor", {
        email: email,
      });

      if (result.status == "success") {
        this.msg = "Gracias por subscribirte a nuestro boletín.";
        this.status = "success";
        this.email_form = "";
      } else {
        this.msg = result.message;
        this.status = "error";
      }
    },
  },
  beforeMount() {
    /* this.gotosec(),
    this.gotosec12(),
    this.gotosec2(),
    this.gotosec22()*/
  },
  mounted() {
    /***changeBK */
    window.addEventListener("load", this.contactbtn);
    window.addEventListener("scroll", this.scrollroll);
    window.addEventListener("scroll", this.changeBK);
    window.addEventListener("scroll", this.changeColor);


    window.addEventListener('resize', this.navFixed)

    window.addEventListener('scroll', () => {
      let scrollPosition = window.scrollY;

      const navegador = document.getElementById('Header')
      if (this.movil && scrollPosition > this.scrollAnterior) {
        navegador.classList.add('ocultar')
      } else {
        navegador.classList.remove('ocultar')
      }

      this.scrollAnterior = scrollPosition

    })

    if (window.innerWidth < 769) {
      this.movil = true;
    }

    /*window.addEventListener("load", this.gotosec);
    window.addEventListener("load", this.gotosec12);
    window.addEventListener("load", this.gotosec2);
    window.addEventListener("load", this.gotosec22);*/
  },
};
</script>
<style scoped>
.pc {
  display: flex !important;
}

.mo {
  display: none !important;
}

.btnhomemenu img {
  width: 1.272vw;
}

#Header {
  position: fixed;
  /*padding: 5.37109375vh 5.17578125vh 0 3.61328125vh;*/
  padding: 5.37109375vh 0 0 0;
  width: 100%;
  z-index: 599;
}

section:has(.white-fil) #Header,
div:has(.white-fil) #Header {
  filter: brightness(0) invert(1) !important;
}

.bright {
  filter: brightness(2);
}

.header-cont {
  display: flex;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  justify-content: space-between;
  z-index: 199;
}

.logo-cont {
  display: flex;
  position: relative;
  z-index: 99;
}

.anchor {
  display: flex;
  position: relative;
  width: 24vh;
  height: 2vh;
  margin: auto auto auto 5.2734375vh;
  overflow: hidden;
  z-index: 1;
  cursor: pointer;
}

.logo {
  width: 19.23828125vh;
  height: auto;
  transition: 0.5s;
}

#checkmenu {
  display: none;
}

.anchor-text {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  transition: all 1s ease;
}

.anchor .anch {
  display: flex;
  animation: scrollText 8s infinite linear;
}

.texto-inf {
  font-family: "Gramatika-Medium";
  font-size: 0.9765625vh;
  line-height: 125.8%;
  letter-spacing: 0.085em;
  text-transform: uppercase;
  /*color: #ef67c2;*/
  transition: all 36s ease;
  margin-right: 1.5vh;
}

.text-anchor-r {
  color: #ef67c2;
  transition: 0.5s;
}

.contact-menu {
  display: flex;
  align-items: center;
  width: 24vh;
  justify-content: space-between;
}

.contactus {
  font-family: "Gramatika-Black";
  font-size: 2.1484375vh;
  line-height: 103.8%;
  text-align: center;
  text-decoration-line: underline;
  color: #987ef3;
  position: relative;
  z-index: 99;
  transition: 0.5s;
}

.menu-btn {
  width: 7.32421875vh;
  height: 7.32421875vh;
  border: 1px solid #987ef3;
  border-radius: 7.32421875vh;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 102;
  transition: 0.5s;
}

.menu-btn:hover {
  transform: scale(1.1);
  border: 1px solid #ef67c2;
}

.bar {
  transition: 0.5s;
  background-color: #987ef3;
}

.menu-btn:hover .bar {
  background-color: #ef67c2;
}

.bars-cont {
  display: flex;
  flex-direction: column;
  height: 1.35vh;
  width: fit-content;
  justify-content: space-between;
  transition: 0.5s;
}

.bar.uno {
  width: 3.125vh;
  height: 0.390625vh;
  transition: 0.5s;
  animation-name: barunoM;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
}

.bar.due {
  width: 3.80859375vh;
  height: 0.390625vh;
  animation-name: barunoM1;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
}

.animation1 .bar.uno {
  width: 3.80859375vh;
  position: relative;
  animation-name: baruno;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  transform: rotate(0);
}

.animation1 .bar.due {
  animation-name: baruno1;
  position: relative;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  position: relative;
  transform: rotate(0);
}

#checkmenu:checked .bars-cont {
  height: 0.390625vh;
}

.bars-cont.animation1 {
  animation-name: heightchange;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
}

.nobr {
  filter: brightness(1) !important;
}

/**MENU  */
.menu-desp {
  width: 100%;
  height: 0;
  pointer-events: none;
  position: fixed;
  left: 0;
  top: 0;
  background: white;
  z-index: 100;
  margin: auto;
  display: flex;
  justify-content: center;
  transition: 1.2s;
  overflow: hidden;
}

#checkmenu:checked~.menu-desp {
  height: 100vh;
  pointer-events: all;
}

.menu-cont {
  display: flex;
  justify-content: space-between;
  /* width: 159.765625vh;*/
  width: fit-content;
  height: 76.171875vh;
  margin: 13vh auto auto;
}

.img-1 {
  width: 55.859375vh;
  height: 76.171875vh;
  transition: 0.5s;
  background-size: 100%;
}

.menu-cont p {
  margin: 0;
}

.menu-items {
  width: 40.367188vh;
}

.img-cont,
.menu-items {
  margin-right: 14.16015625vh;
}

.menu-items .title {
  font-family: Gramatika-Black;
  font-size: 13.671875vh;
  line-height: 93.3%;
  color: #000000;
  margin-bottom: 9.29688vh;
  margin-top: 4.29688vh;
  padding-left: 1.8vw;
}

a.menu-i {
  display: flex;
  margin-bottom: 6.1vh;
  transition: 0.5s;
}

.t-cont a.menu-i:nth-child(2) {
  margin-bottom: 0;
}

a.menu-i span {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 700;
  font-size: 0.9765625vh;
  line-height: 103.8%;
  text-align: center;
  color: #987ef3;
  top: 1vh;
  margin: 0 1.4vh;
  position: relative;
}

a.menu-i p {
  font-family: Gramatika-Medium;
  font-size: 4.39453125vh;
  /*line-height: 202.8%;*/
  line-height: 4.39453125vh;
  color: #000000;
  text-decoration-color: transparent;
  transition: 0.5s;
}

a.menu-i:hover,
a.menu-i:hover p {
  color: #e26bbf;
  text-decoration-color: #e26bbf;
}

.s-titulo {
  font-family: Gramatika-Bold;
  font-size: 2.44140625vh;
  line-height: 205.3%;
  color: #000000;
}

p.s-descr {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 400;
  font-size: 1.26953125vh;
  line-height: 205.3%;
  color: #000;
  width: 33.10546875vh;
  margin-bottom: 3.125vh;
}

.sus-con-cont {
  width: 40.72265625vh;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.txt-cont {
  background-position-x: right;
  background-position-y: center;
  border-bottom: 1px solid black;
}

.txt-cont form {
  display: flex;
  align-items: center;
}

.txt-cont input[type="text"] {
  width: 100%;
  height: 5.2734375vh;
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 500;
  font-size: 1.112564102564vw;
  line-height: 213.3%;
  color: #000000;
  border: 0;
  background-color: transparent;
}

.btnsbt {
  background-image: url("../../assets/send-btn.svg");
  background-size: 3.027344vh;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  border: none;
  width: 2.027vw;
  height: 2.027vw;
  padding: 0;
  cursor: pointer;
}

.sm-logo {
  width: 1.953125vh;
  height: 1.953125vh;
  transition: 0.5s;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.sm-logo:hover {
  opacity: 0.5;
}

.fb-icon {
  width: 0.953125vh;
}

.sm-cont {
  width: 14.74609375vh;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.953125vh;
}

.copyright {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: normal;
  font-size: 1.171875vh;
  line-height: 205.3%;
  color: #000000;
}

.sm-cr-cont {
  padding-left: 4vh;
}

.t-cont {
  /* margin-bottom: 18.84765625vh;*/
  margin-bottom: 25.847656vh;
}

/**menu btn */
@keyframes heightchange {
  0% {
    height: 1.35vh;
  }

  25% {
    height: 1.35vh;
  }

  50% {
    height: 1.35vh;
  }

  100% {
    height: 2vh;
    animation-duration: 1.5s;
  }
}

@keyframes baruno {
  0% {
    margin-top: 0;
    width: 3.125vh;
  }

  25% {
    margin-top: 0;
    width: 3.80859375vh;
    top: 0;
  }

  50% {
    margin-top: 0.5vh;
    top: 0.5vh;
    transform: rotate(0);
  }

  100% {
    margin-top: 0.5vh;
    top: 0.5vh;
    transform: rotate(50deg);
    height: 0.390625vh;
    animation-duration: 1.5s;
  }
}

@keyframes baruno1 {
  0% {
    margin-top: 0;
  }

  25% {
    margin-top: 0;
  }

  50% {
    margin-top: 0;
    top: 0;
    transform: rotate(0);
  }

  100% {
    margin-top: 0;
    top: -0.6vh;
    transform: rotate(135deg);
    height: 0.390625vh;
    animation-duration: 1.5s;
  }
}

@keyframes barunoM {
  0% {
    margin-top: 0.5vh;
    top: 0.5vh;
    transform: rotate(50deg);
    height: 0.390625vh;
    animation-duration: 1.5s;
  }

  25% {
    margin-top: 0.5vh;
    top: 0.5vh;
    transform: rotate(0);
  }

  50% {
    margin-top: 0;
    width: 3.80859375vh;
    top: 0;
  }

  100% {
    margin-top: 0;
    width: 3.125vh;
  }
}

@keyframes barunoM1 {
  0% {
    margin-top: 0;
    top: -0.6vh;
    transform: rotate(135deg);
    height: 0.390625vh;
    animation-duration: 1.5s;
  }

  25% {
    margin-top: 0;
    top: 0;
    transform: rotate(0);
  }

  50% {
    margin-top: 0;
  }

  100% {
    margin-top: 0;
  }
}

/**rest of animations  */
@keyframes scrollText {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-50%);
  }
}

@media (max-width: 1040px) {
  .img-1 {
    display: none;
  }

  .menu-items .title {
    font-size: 5.859375vh;
    margin-bottom: 6.4453125vh;
  }

  a.menu-i {
    margin-bottom: 1vh;
  }

  a.menu-i p {
    font-size: 2.9296875vh;
  }

  .img-cont {
    display: none;
  }

  .menu-cont {
    width: 68%;
  }
}

@media (max-width: 768px) {


  #Header {
    transition: transform 0.6s linear;
  }

  .ocultar {
    transform: translateY(-200%);
  }

  section:has(.scr) #Header {
    filter: none !important;
  }

  .pc {
    display: none !important;
  }

  .mo {
    display: flex !important;
  }

  #Header {
    padding: 7.242990654205607vw 0 0 0;
    width: 100vw;
    transition: 0.5s;
  }

  #Header.scr {
    background-color: #e4e4f9;
    padding: 3.5vw 0 6.5vw 0;
  }

  .header-cont {
    width: 81%;
  }

  .anchor {
    position: absolute;
    top: 7.916256vh;
    left: 0;
    margin-left: 0;
    width: 76vw;
  }

  .anchor .anch {
    animation: scrollText 12s infinite linear;
  }

  .texto-inf {
    font-family: "Gramatika-Medium";
    font-size: 1.14704433497536944vh;
    line-height: 125.8%;
    letter-spacing: 0.1vw;
    text-transform: uppercase;
    /*color: #ef67c2;*/
    transition: all 1s ease;
    margin: 0 2.2vh;
  }

  .contactus {
    display: none;
  }

  .contact-menu {
    width: fit-content;
  }

  /***MENU */

  .movhid {
    display: none !important;
  }

  .menu-cont {
    flex-direction: column;
    width: 90%;
    height: 82.171875vh;
    margin: 12.70093457943925vw auto 0;
  }

  .btnhomemenu {
    margin-left: 2vw;
  }

  .btnhomemenu img {
    width: 5.072vw;
  }

  /**/
  .t-cont a.menu-i {
    margin: 11.73913043478261vw 0 0.570313vh;
  }

  .t-cont {
    margin-bottom: 3vw;
  }

  a.menu-i span {
    font-size: 1.8691588785046727vw;
    line-height: 103.8%;
    text-align: left;
    color: #987ef3;
    top: 2vw;
    margin: 0 1.5vh 0 1.5vh;
    position: relative;
  }

  p.s-descr {
    font-size: 2.564102564102564vw;
    line-height: 5.264102564102564vw;
    color: #000000;
    width: 100%;
    margin-bottom: 5.264102564102564vw;
  }

  .sus-con-cont {
    justify-content: flex-start;
    width: 37.722656vh;
    padding-top: 4vw;
  }

  .susc-cont {
    padding-left: 4.4vh;
  }

  .s-titulo[data-v-2e713bd2] {
    font-size: 4.102564102564102vw;
    line-height: 8.423076923076923vw;
    color: #000000;
  }

  .btnsbt {
    width: 7.948717948717948vw;
    height: 7.948717948717948vw;
  }

  .menu-items .title {
    padding-left: 4vh;
    font-size: 14.018691588785046vw;
    margin-bottom: 8vw;
    margin-top: 10.591787439613526vw;
  }

  .contmm {
    font-family: "Gramatika-medium";
    font-size: 6.280193236714976vw;
    color: #987ef3 !important;
    padding-left: 4vh;
  }

  .txt-cont input[type="text"] {
    font-size: 2.564102564102564vw;
    line-height: 213.3%;
  }

  .sm-cr-cont {
    padding-left: 4.4vh;
  }

  @keyframes scrollText {
    from {
      transform: translateX(0%);
    }

    to {
      transform: translateX(-50%);
    }
  }
}

@media (min-width: 768px) {

  section:has(.white-fil) #Header,
  div:has(.white-fil) #Header {
    position: absolute !important;
  }
}
</style>